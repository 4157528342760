module.exports = [{
      plugin: require('/vercel/394f6d4c/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143526815-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/vercel/394f6d4c/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/394f6d4c/src/locales","languages":["en","hu"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/vercel/394f6d4c/src/components/Redirect.js"},
    },{
      plugin: require('/vercel/394f6d4c/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
